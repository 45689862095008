<template>
  <div>
    <h1 class="heading">My Events</h1>
    <div class="event_header" style="margin-bottom:15px;width:100%;">
      <div style="display: flex">
        <div style="flex:1;margin-right:15px;">

        <v-select
          v-model="objectItem"
          hide-details
          :items="objects.list"
          item-value="id"
          item-text="name"
          return-object
          dense
          style="max-width: 300px"
          outlined
          @change="changeHotel"
        >
          <template v-slot:selection="{ item }">
                            <div v-if="$store.state.isMobile">{{ item.name.slice(0,5) }}...</div>
                <div v-if="!$store.state.isMobile">{{ item.name }}</div>
            <div v-if="item.tariff_id === 1" class="tariff_obj">free</div>
            <div v-if="item.tariff_id === 2" class="tariff_obj">standart</div>
            <div v-if="item.tariff_id === 3" class="tariff_obj">pro</div>
          </template>
          <template v-slot:item="{ item }">
                            <div v-if="$store.state.isMobile">{{ item.name.slice(0,5) }}...</div>
                <div v-if="!$store.state.isMobile">{{ item.name }}</div>
            <div v-if="item.tariff_id === 1" class="tariff_obj">free</div>
            <div v-if="item.tariff_id === 2" class="tariff_obj">standart</div>
            <div v-if="item.tariff_id === 3" class="tariff_obj">pro</div>
          </template>
        </v-select>
        </div>
        <div class="filter__item filter__item_date" style="flex:1;" v-if="page === 'events'">
          <DatePicker
            :shortcuts="shortcuts"
            format="DD.MM.YYYY"
            v-model="dates"
            @change="changeDates"
            type="date"
            range
            placeholder="Select datetime range"
          >
            <template v-slot:input>
              <v-text-field
                solo
                flat
                outlined
                dense
                :placeholder="$t('statistics[\'Период\']')"
                readonly
                hide-details
                v-model="date_model"
              ></v-text-field>
            </template>
          </DatePicker>
        </div>
      </div>
      <div style="flex:1;display: flex;justify-content: flex-end">
        <v-btn color="primary" outlined dense @click="openAddEventDialog()" small v-if="page === 'events'"
        >+ Add event</v-btn
        >
        <v-btn color="primary" outlined dense small v-if="page === 'categories'" @click="openAddEventCategoryDialog"
        >+ Add category</v-btn
        >
      </div>
    </div>
    <div class="event_body" v-if="page === 'events'">

      <v-btn color="primary" outlined dense small @click="backToCategoryList"
      ><v-icon>mdi-chevron-left</v-icon> Back to category list</v-btn
      >
      <div
        v-if="selectedRows.length"
        style="
          display: flex;
          padding-bottom: 15px;
          justify-content: flex-end;
          width: 100%;
          height: 50px;
        "
      >
        <v-menu offset-y open-on-hover bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon style="cursor: pointer;" color="rgba(0, 0, 0, 0.54)" v-bind="attrs" v-on="on"
              >mdi-cog</v-icon
            >
          </template>
          <v-list>
            <v-list-item>
              <v-list-item-title
                style="cursor: pointer"
                @click="deleteAllSelectedShow"
                >Delete</v-list-item-title
              >
            </v-list-item>
            <v-list-item>
              <v-list-item-title style="cursor: pointer" @click="activeAllShow"
                >Active</v-list-item-title
              >
            </v-list-item>
            <v-list-item>
              <v-list-item-title style="cursor: pointer" @click="disableAllShow"
                >Disable</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <v-tabs v-model="selected_lang">
        <v-tab v-for="(selected_lang, i) in selected_langs" :key="i" @change="changeLang(selected_lang)">
          <img
            :src="
              landing.advanced.langs.find((x) => x.value === selected_lang).flag
            "
            alt=""
            style="width: 20px; height: 15px; margin-right: 5px"
          />
          {{ selected_lang }}
        </v-tab>
        <v-tab-item v-for="lang in selected_langs" :key="lang">
          <v-data-table
            class="table rooms__table elevation-1"
            :headers="headers"
            hide-default-footer
            no-data-text="You don't have events yet, it's time to create them"
            :items="filteredEvents"
            v-model="selectedRows"
            item-key="id"
            :items-per-page="999"
          >
            <template v-slot:body="{ items }">
              <tbody v-if="filteredEvents.length">
                <tr
                  v-for="item in items"
                  :key="item.id"
                  @click="rowClicked(item)"
                  :style="`background-color:${
                    selectedRows.indexOf(item.id) > -1
                      ? 'rgba(0, 125, 181,0.1)'
                      : '#ffffff'
                  }`"
                >
                  <td class="d-block d-sm-table-cell" style="text-align: center">
                    <img
                      :src="
                        item.main_image
                          ? item.main_image
                          : require('@/assets/none.png')
                      "
                      alt=""
                      style="max-height: 60px; max-width: 60px"
                    />
                  </td>
                  <td class="d-block d-sm-table-cell">
                    <v-layout
                      justify-center
                      :style="`color:${
                        +$moment(item.date).format('x') < +$moment().format('x')
                          ? '#ccc'
                          : '#000'
                      };`"
                    >
                      {{ formatDate(item.date) + " " + item.time }}
                    </v-layout>
                  </td>
                  <td class="d-block d-sm-table-cell">
                    <v-layout justify-center>
                      {{ item.name[lang] }}
                    </v-layout>
                  </td>

                  <td class="d-block d-sm-table-cell" style="text-align: center">
                    <v-chip
                      class="ma-2"
                      :color="item.show_event ? 'green' : 'red'"
                      text-color="white"
                      style="cursor: pointer"
                      @click.stop="changeEventVisibility(item)"
                    >
                      {{ item.show_event ? "Active" : "Inactive" }}
                    </v-chip>
                  </td>
                <td class="d-block d-sm-table-cell" style="text-align: center">
                    <v-chip
                            class="ma-2"
                            :color="item.show_on_main_page ? 'green' : 'red'"
                            text-color="white"
                            style="cursor: pointer"
                            @click.stop="changeEventVisibilityOnMain(item)"
                    >
                        {{ item.show_on_main_page ? "Showed" : "Hidden" }}
                    </v-chip>
                </td>
                  <td class="d-block d-sm-table-cell">
                    <v-layout justify-center>
                      <v-icon
                        color="#007db5"
                        style="margin-right: 5px"
                        @click.stop="openEditEventDialog(item)"
                        >mdi-pencil</v-icon
                      >
                      <v-icon
                        color="#7F8385"
                        @click.stop="openDeleteDialog(item)"
                        >mdi-delete</v-icon
                      >
                    </v-layout>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td class="d-block d-sm-table-cell"
                    colspan="5"
                    style="
                      text-align: center;
                      padding: 0 15px;
                      font-weight: normal;
                    "
                  >
                    You don't have events yet, it's time to create them
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-tab-item>
      </v-tabs>
    </div>
    <div class="event_body" v-if="page === 'categories'">
      <v-tabs v-model="selected_lang">
        <v-tab v-for="(selected_lang, i) in selected_langs" :key="i" @change="changeLang(selected_lang)">
          <img
              :src="
              landing.advanced.langs.find((x) => x.value === selected_lang).flag
            "
              alt=""
              style="width: 20px; height: 15px; margin-right: 5px"
          />
          {{ selected_lang }}
        </v-tab>
        <v-tab-item v-for="lang in selected_langs" :key="lang">
          <v-data-table
              class="table rooms__table elevation-1"
              :headers="headersCategories"
              hide-default-footer
              no-data-text="You don't have event categories yet, it's time to create them"
              :items="eventCategories"
              item-key="id"
              :items-per-page="999"
          >
            <template v-slot:body="{ items }">
              <tbody v-if="eventCategories.length">
              <tr
                  v-for="item in items"
                  :key="item.id"
                  @click="rowClickedCategory(item)"
                  :style="`background-color:${
                    selectedRowsCategories.indexOf(item.id) > -1
                      ? 'rgba(0, 125, 181,0.1)'
                      : '#ffffff'
                  }`"
              >
                <td class="d-block d-sm-table-cell" style="text-align: center">
                  {{item.id}}
                </td>
                <td class="d-block d-sm-table-cell" style="text-align: center">
                  {{item.text[lang]}}
                </td>
                <td class="d-block d-sm-table-cell" style="text-align: center" @click="openEventList(item)">
                  {{item.events.length}}
                </td>
                <!--                  <td class="d-block d-sm-table-cell">-->
                <!--                    <v-layout justify-center>-->
                <!--                      {{ item.description[lang] }}-->
                <!--                    </v-layout>-->
                <!--                  </td>-->

                <td class="d-block d-sm-table-cell" style="text-align: center">
                  <v-menu offset-y open-on-hover bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="primary" dark v-bind="attrs" v-on="on"
                             outlined
                             small
                             dense>
                        <v-icon>mdi-cog</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item style="cursor:pointer;" @click="openAddEventDialog(item)">
                        <v-list-item-title>
                          Add event
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                          style="cursor:pointer;" @click="openEventList(item)"
                      >
                        <v-list-item-title>List events</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                          style="cursor:pointer;" @click="openEditEventCategory(item)"
                      >
                        <v-list-item-title>Edit category</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                          style="cursor:pointer;color:red;" @click="openDeleteEventCategory(item.id)" v-if="eventCategories.length > 1"
                      >
                        <v-list-item-title>Delete category</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
              </tbody>
              <tbody v-else>
              <tr>
                <td class="d-block d-sm-table-cell"
                    colspan="5"
                    style="
                      text-align: center;
                      padding: 0 15px;
                      font-weight: normal;
                    "
                >
                  You don't have event categories yet, it's time to create them
                </td>
              </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-tab-item>
      </v-tabs>
    </div>

    <v-dialog :fullscreen="$store.state.isMobile" v-model="addEventDialog" max-width="700px" :retain-focus="false" eager>
      <v-card>
        <v-icon
          style="top: 10px; right: 10px; position: absolute"
          class="close__modal"
          @click="closeEventDialog"
          >mdi-close</v-icon
        >
        <v-card-title>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <span class="text-h5">New Event</span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>
        <v-card-text>
          <div style="display:flex;">
            <div class="date_time" style="flex:1;">
              <div class="date">
                <div @click="addEventDateDialog = !addEventDateDialog">
                    <label class="label">Date</label>
                    <v-combobox
                        :value="changeDatesArrFormat(addEventDefault.date,addEventDefault.times)"
                        multiple
                        chips
                        outlined
                        dense
                        hide-details
                        small-chips
                        readonly
                    ></v-combobox>
                </div>
                <div v-if="addEventDateDialog" style="display:flex;flex-direction:column;margin-top:15px;border:1px solid #ccc;border-radius: 5px;padding:5px;width: 100%;">
                  <div style="display:flex;width:100%;">
                      <v-layout justify-center
                                align-center column style="flex:1;">
                        <label class="label">
                          Date
                        </label>
                        <v-date-picker
                            no-title
                            :disabled="addEventDefault.date.length > addEventDefault.times.length"
                            v-model="addEventDefault.date"
                            multiple
                        ></v-date-picker>
                      </v-layout>
                    <v-layout justify-center
                              align-center column style="flex:1;">
                      <label class="label">
                        Time
                      </label>
                      <v-layout justify-center
                                align-center>
                        <v-layout align-center
                                  justify-center
                                  style="max-width: 100px">
                          <v-layout column align-center>
                            <v-btn icon
                                   class="my-0"
                                   style="position: relative;"
                                   @click="onAddHourClicked">
                              <v-icon large>mdi-chevron-up</v-icon>
                            </v-btn>
                            <v-text-field v-model="hourModel"
                                          style="width: 40px;text-align: center"
                                          class="pt-0 my-0 no-underline align-center"
                                          type="number"
                                          :min="minHour"
                                          :max="maxHour"
                                          step="1"
                                          required
                                          hide-details
                            ></v-text-field>
                            <v-btn icon
                                   class="my-0"
                                   style="position: relative;"
                                   @click="onSubtractHourClicked">
                              <v-icon large>mdi-chevron-down</v-icon>
                            </v-btn>
                          </v-layout>

                          <div class="grey--text"
                               style="font-size: 18px;
                        position: relative;
                        bottom: 2px">:</div>

                          <v-layout align-center style="margin-left: 12px">
                            <v-layout column align-center>
                              <v-btn icon
                                     class="my-0"
                                     style="position: relative;"
                                     @click="onAddMinuteClicked">
                                <v-icon large>mdi-chevron-up</v-icon>
                              </v-btn>
                              <v-text-field v-model="minuteModel"
                                            style="width: 40px;text-align: center"
                                            class="pt-0 my-0 no-underline align-center"
                                            type="number"
                                            :min="minMinute"
                                            :max="maxMinute"
                                            step="1"
                                            required
                                            hide-details
                              ></v-text-field>
                              <v-btn icon
                                     class="my-0"
                                     style="position: relative;"
                                     @click="onSubtractMinuteClicked">
                                <v-icon large>mdi-chevron-down</v-icon>
                              </v-btn>
                            </v-layout>
                          </v-layout>

                        </v-layout>

                      </v-layout>
                    </v-layout>
                  </div>
                  <div style="display: flex;justify-content: flex-end">
                    <v-btn  outlined small color="primary" @click="addTime()">Done</v-btn>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
          >
            <div class="event__img" style="margin-right: 15px;">
              <label class="label">Upload main image</label>
              <vue-dropzone
                ref="addEventMainDropzone"
                id="addEventMainDropzone"
                :options="dropzoneOptionsMain"
                @vdropzone-sending="customEvent"
                @vdropzone-removed-file="handleRemoveAddEventMain"
                maxFiles="1"
                @vdropzone-success="handleSuccessAddEventMain"
                @vdropzone-max-files-exceeded="logoMaxFileSexceededAddEventMain"
              >
              </vue-dropzone>
            </div>
            <div class="event__img" style="width: 70%">
              <label class="label">Upload detail images</label>
              <vue-dropzone
                ref="addEventDetailDropzone"
                id="addEventDetailDropzone"
                :options="dropzoneOptionsDetail"
                @vdropzone-sending="customEvent"
                @vdropzone-removed-file="
                  (file) => handleRemoveAddEventDetail(file)
                "
                @vdropzone-success="handleSuccessAddEventDetail"
              >
              </vue-dropzone>
            </div>
          </div>
          <div class="event__img" v-if="addEventDefault.images.length > 1">
            <label class="label">You can change image order</label>
            <draggable
              :options="{disabled: isMobile}"
              style="display: flex; margin-bottom: 10px"
              :list="addEventDefault.images"
            >
              <div
                v-for="(img, index) in addEventDefault.images"
                :key="img"
                style="margin-right: 15px; position: relative"
              >
                <div
                  style="
                    border: 2px solid #007db5;
                    color: #007db5;
                    padding: 3px 6px;
                    font-weight: bold;
                    background: #fafafa;
                    position: absolute;
                    top: 0;
                    left: 0;
                  "
                >
                  {{ index + 1 }}
                </div>
                <img
                  :src="img"
                  :alt="img"
                  style="width: 150px; height: 150px; object-fit: cover"
                />
              </div>
            </draggable>
          </div>
            <div style="display: flex;align-items: center;">
                <label class="label" style="margin-right: 15px;display: block;margin-bottom: 0;">Show on main page</label>
                <v-switch color="#6ab64a" style="margin: 0;padding:0;" v-model="addModelMainPage" hide-details @change="changeAddModelMainPage(addEventDefault)"></v-switch>
            </div>
          <v-tabs>
              <v-tab v-for="(selected_lang, i) in selected_langs" :key="i" @change="changeLang(selected_lang)">
                  <img
                          :src="
              landing.advanced.langs.find((x) => x.value === selected_lang).flag
            "
                          alt=""
                          style="width: 20px; height: 15px; margin-right: 5px"
                  />
                  {{ selected_lang }}
              </v-tab>
            <v-tab-item
              v-for="lang in selected_langs"
              :key="lang"
              style="padding-top: 15px"
            >
              <div style="display: flex">
                <div style="padding-right: 15px; width: 50%">
                  <label class="label">Name</label>
                  <v-text-field
                    outlined
                    dense
                    hide-details
                    v-model="addEventDefault.name[lang]"
                  ></v-text-field>
                </div>
                <div style="width: 50%">
                  <label class="label">Video Link</label>
                  <v-text-field
                    outlined
                    dense
                    hide-details
                    v-model="addEventDefault.video_link[lang]"
                  ></v-text-field>
                </div>
              </div>
              <div class="event__textfield">
                <label class="label">Description</label>
                <Editor
                  api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
                  :init="options"
                  v-model="addEventDefault.description[lang]"
                >
                </Editor>
              </div>
              <div class="event__textfield">
                <label class="label">Full Description</label>
                <Editor
                  api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
                  :init="options"
                  v-model="addEventDefault.full_description[lang]"
                >
                </Editor>
              </div>
            </v-tab-item>
          </v-tabs>
        </v-card-text>

        <v-card-actions style="padding: 0 36px 20px 36px">
          <v-spacer></v-spacer>
          <v-btn
            class="object__submit btn btn_primary"
            color="primary"
            small outlined
            @click="saveNewEvent"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog :fullscreen="$store.state.isMobile" v-model="addEventCategoryDialog" max-width="700px" :retain-focus="false" eager>
      <v-card>
        <v-icon
            style="top: 10px; right: 10px; position: absolute"
            class="close__modal"
            @click="closeEventCategoryDialog"
        >mdi-close</v-icon
        >
        <v-card-title>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <span class="text-h5">New event category</span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>
        <v-card-text>
          <v-tabs>
            <v-tab v-for="(selected_lang, i) in selected_langs" :key="i" @change="changeLang(selected_lang)">
              <img
                  :src="
              landing.advanced.langs.find((x) => x.value === selected_lang).flag
            "
                  alt=""
                  style="width: 20px; height: 15px; margin-right: 5px"
              />
              {{ selected_lang }}
            </v-tab>
            <v-tab-item
                v-for="lang in selected_langs"
                :key="lang"
                style="padding-top: 15px"
            >
              <div style="display: flex;padding:0 15px;">
                <div>
                  <label class="label">Category name</label>
                  <v-text-field
                      style="margin-top:15px;"
                      outlined
                      dense
                      hide-details
                      v-model="addEventCategoryModel.text[lang]"
                  ></v-text-field>
                </div>
              </div>
            </v-tab-item>
          </v-tabs>
        </v-card-text>

        <v-card-actions style="padding: 0 36px 20px 36px">
          <v-spacer></v-spacer>
          <v-btn
              class="object__submit btn btn_primary"
              color="primary"
              small outlined
              @click="saveNewEventCategory"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog :fullscreen="$store.state.isMobile" v-model="editEventCategoryDialog" max-width="700px" :retain-focus="false" eager>
      <v-card>
        <v-icon
            style="top: 10px; right: 10px; position: absolute"
            class="close__modal"
            @click="editEventCategoryDialog = false"
        >mdi-close</v-icon
        >
        <v-card-title>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <span class="text-h5">Edit event category</span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>
        <v-card-text>
          <v-tabs>
            <v-tab v-for="(selected_lang, i) in selected_langs" :key="i" @change="changeLang(selected_lang)">
              <img
                  :src="
              landing.advanced.langs.find((x) => x.value === selected_lang).flag
            "
                  alt=""
                  style="width: 20px; height: 15px; margin-right: 5px"
              />
              {{ selected_lang }}
            </v-tab>
            <v-tab-item
                v-for="lang in selected_langs"
                :key="lang"
                style="padding-top: 15px"
            >
              <div style="display: flex;padding:0 15px;">
                <div>
                  <label class="label">Category name</label>
                  <v-text-field
                      style="margin-top:15px;"
                      outlined
                      dense
                      hide-details
                      v-model="editEventCategoryModel.text[lang]"
                  ></v-text-field>
                </div>
              </div>
            </v-tab-item>
          </v-tabs>
        </v-card-text>

        <v-card-actions style="padding: 0 36px 20px 36px">
          <v-spacer></v-spacer>
          <v-btn
              class="object__submit btn btn_primary"
              color="primary" outlined
              small
              @click="editEventCategoryConfirm"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog :fullscreen="$store.state.isMobile" v-model="editEventDialog" max-width="700px">
      <v-card>
        <v-icon
          style="top: 10px; right: 10px; position: absolute"
          class="close__modal"
          @click="closeEditEventDialog"
          >mdi-close</v-icon
        >
        <v-card-title>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <span class="text-h5">Edit Event</span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>
        <v-card-text>
          <div class="date_time">
            <div class="date" style="width: 50%; padding-right: 15px">
              <v-menu
                v-model="editEventDateDialog"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                absolute
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <label class="label">Date</label>
                  <v-text-field
                    :value="formatDate(editEventDefault.date)"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    outlined
                    dense
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="editEventDefault.date"
                  @input="closeEditEventDate"
                ></v-date-picker>
              </v-menu>
            </div>
            <div class="time" style="width: 50%">
              <v-menu
                ref="menu"
                v-model="editEventTimeDialog"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="editEventDefault.time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <label class="label">Time</label>
                  <v-text-field
                    v-model="editEventDefault.time"
                    prepend-inner-icon="mdi-clock-time-four-outline"
                    readonly
                    outlined
                    dense
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="editEventTimeDialog"
                  v-model="editEventDefault.time"
                  format="24hr"
                  @click:minute="$refs.menu.save(editEventDefault.time)"
                ></v-time-picker>
              </v-menu>
            </div>
          </div>
          <div class="event__img">
            <label class="label">Change main image</label>
            <vue-dropzone
              ref="editEventMainDropzone"
              id="editEventMainDropzone"
              :options="dropzoneOptionsMain"
              @vdropzone-sending="customEvent"
              @vdropzone-removed-file="handleRemoveEditEventMain"
              maxFiles="1"
              @vdropzone-success="handleSuccessEditEventMain"
              @vdropzone-max-files-exceeded="logoMaxFileSexceededEditEventMain"
              @vdropzone-mounted="mountEditMainDropzone"
            >
            </vue-dropzone>
          </div>
          <div class="event__img">
            <label class="label">Change detail images</label>
            <vue-dropzone
              ref="editEventDetailDropzone"
              id="editEventDetailDropzone"
              :options="dropzoneOptionsDetail"
              @vdropzone-sending="customEvent"
              @vdropzone-removed-file="
                (file) => handleRemoveEditEventDetail(file)
              "
              @vdropzone-success="handleSuccessEditEventDetail"
              @vdropzone-mounted="mountEditDetailDropzone"
            >
            </vue-dropzone>
          </div>
          <div class="event__img" v-if="editEventDefault.images.length > 1">
            <label class="label">You can change image order</label>
            <draggable
              :options="{disabled: isMobile}"
              style="display: flex; margin-bottom: 10px"
              :list="editEventDefault.images"
            >
              <div
                v-for="(img, index) in editEventDefault.images"
                :key="img"
                style="margin-right: 15px; position: relative"
              >
                <div
                  style="
                    border: 2px solid #007db5;
                    color: #007db5;
                    padding: 3px 6px;
                    font-weight: bold;
                    background: #fafafa;
                    position: absolute;
                    top: 0;
                    left: 0;
                  "
                >
                  {{ index + 1 }}
                </div>
                <img
                  :src="img"
                  :alt="img"
                  style="width: 150px; height: 150px; object-fit: cover"
                />
              </div>
            </draggable>
          </div>
            <div style="display: flex;align-items: center;">
                <label class="label" style="margin-right: 15px;display: block;margin-bottom: 0;">Show on main page</label>
                <v-switch color="#6ab64a" style="margin: 0;padding:0;" v-model="addModelMainPage" hide-details @change="changeAddModelMainPage(editEventDefault)"></v-switch>
            </div>
          <v-tabs>
              <v-tab v-for="(selected_lang, i) in selected_langs" :key="i" @change="changeLang(selected_lang)">
                  <img
                          :src="
              landing.advanced.langs.find((x) => x.value === selected_lang).flag
            "
                          alt=""
                          style="width: 20px; height: 15px; margin-right: 5px"
                  />
                  {{ selected_lang }}
              </v-tab>

            <v-tab-item
              v-for="lang in selected_langs"
              :key="lang"
              style="padding-top: 15px"
            >
              <div style="padding-right: 15px; width: 50%">
                <label class="label">Category</label>
                <v-select
                    flat
                    dense
                    outlined
                    hide-details
                    placeholder="Choose category"
                    :items="eventCategories"
                    style="margin-bottom: 15px;"
                    item-text="text"
                    item-value="id"
                    v-model="editEventDefault.event_category_id"
                >
                  <template v-slot:selection="{ item }">
                    <div>{{ item.text[lang] }}</div>
                  </template>
                  <template v-slot:item="{ item }">
                    <div>{{ item.text[lang] }}</div>
                  </template>
                </v-select>
              </div>
              <div style="display: flex">
                <div style="padding-right: 15px; width: 50%">
                  <label class="label">Name</label>
                  <v-text-field
                    outlined
                    dense
                    hide-details
                    v-model="editEventDefault.name[lang]"
                  ></v-text-field>
                </div>
                <div style="width: 50%">
                  <label class="label">Video Link</label>
                  <v-text-field
                    outlined
                    dense
                    hide-details
                    v-model="editEventDefault.video_link[lang]"
                  ></v-text-field>
                </div>
              </div>
              <div class="event__textfield">
                <label class="label">Description</label>
                <Editor
                  api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
                  :init="options"
                  v-model="editEventDefault.description[lang]"
                >
                </Editor>
              </div>
              <div class="event__textfield">
                <label class="label">Full Description</label>
                <Editor
                  api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
                  :init="options"
                  v-model="editEventDefault.full_description[lang]"
                >
                </Editor>
              </div>
            </v-tab-item>
          </v-tabs>
        </v-card-text>
        <v-card-actions style="padding: 0 36px 20px 36px">
          <v-spacer></v-spacer>
          <v-btn
            class="object__submit btn btn_primary"
            color="primary"
            outlined
            small
            @click="saveEditEvent"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <PopupSuccess ref="PopupSuccess" :title="'Thank you!'" :content="successMessage" />
    <PopupDelete
      ref="PopupDeleteAll"
      title="Are you sure about delete selected events?"
      @confirm="deleteAllSelected"
    />
    <PopupDelete
        ref="PopupDeleteCategory"
        title="Are you sure about delete event category?"
        @confirm="deleteEventCategoryConfirm"
    />
    <PopupDelete
      ref="PopupDelete"
      :title="`Are you sure about delete ${deleteEventId?deleteEventId.name[landing.current_lang]:''}?`"
      @confirm="deleteEventConfirm()"
    />
    <PopupConfirm
      ref="PopupConfirmActiveAll"
      @confirm="activeAllConfirm"
      :title="`Are you sure about active selected items?`"
    />
    <PopupConfirm
      ref="PopupConfirmDisableAll"
      @confirm="disableAllConfirm"
      :title="`Are you sure about disable selected items?`"
    />
  </div>
</template>

<script>
import titleMixin from "@/mixins/titleMixin";
import store from "@/store";
import {mapActions, mapMutations, mapState} from "vuex";
import vueDropzone from "vue2-dropzone";
import draggable from "vuedraggable";
import PopupSuccess from "@/components/Popup/PopupSuccess.vue";
import PopupDelete from "@/components/Popup/PopupDelete.vue";
import PopupConfirm from "@/components/Popup/PopupConfirm.vue";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import "vue2-datepicker/index.css";
import DatePicker from "vue2-datepicker";
export default {
  name: "Events",
  mixins: [titleMixin],
  components: {
    PopupConfirm,
    vueDropzone,
    draggable,
    PopupSuccess,
    PopupDelete,
    DatePicker,
  },
  title() {
    return "Events";
  },
  created() {
    this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  },
  data() {
    const now = new Date();
    return {
      isMobile:false,
      page:'categories',
      shortcuts: [
        {
          text: "Today",
          onClick() {
            const start = new Date();
            const end = new Date();
            start.setTime(start.getTime() - 1 * 24 * 3600 * 1000);
            const date = [start, end];
            return date;
          },
        },
        {
          text: "Yesterday",
          onClick() {
            const start = new Date();
            const end = new Date();
            end.setTime(end.getTime() + 1 * 24 * 3600 * 1000);
            const date = [start, end];
            return date;
          },
        },
        {
          text: "Last 7 day",
          onClick() {
            const start = new Date();
            const end = new Date();
            start.setTime(start.getTime() - 7 * 24 * 3600 * 1000);
            const date = [start, end];
            return date;
          },
        },
        {
          text: "Last 30 day",
          onClick() {
            const start = new Date();
            const end = new Date();
            start.setTime(start.getTime() - 30 * 24 * 3600 * 1000);
            const date = [start, end];
            return date;
          },
        },
        {
          text: "This month",
          onClick() {
            const start = new Date();
            const end = new Date();
            const firstDayCurrentMonth = new Date(
              start.getFullYear(),
              start.getMonth(),
              1
            );
            const lastDayCurrefntMonth = new Date(
              end.getFullYear(),
              end.getMonth() + 1,
              0
            );
            const date = [firstDayCurrentMonth, lastDayCurrefntMonth];
            return date;
          },
        },
        {
          text: "Last month",
          onClick() {
            const dateLast = new Date();
            const firstDayCurrentMonth = new Date(
              dateLast.getFullYear(),
              dateLast.getMonth() - 1,
              1
            );
            const lastDayCurrefntMonth = new Date(
              dateLast.getFullYear(),
              dateLast.getMonth(),
              0
            );
            const date = [firstDayCurrentMonth, lastDayCurrefntMonth];
            return date;
          },
        },
      ],
      addModelMainPage:false,
      menu_dates: false,
      date_model: null,
      dates: [],
      successMessage: "",
      selectedRows: [],
      selectedRowsCategories:[],
      deleteEventId: null,
      dropzoneOptionsMain: {
        thumbnailMethod: "contain",
        acceptedFiles: "image/*",
        addRemoveLinks: true,
        destroyDropzone: true,
        maxFiles: 1,
        url: () => {
          return process.env.VUE_APP_API_URL + "upload-image";
        },
      },
      dropzoneOptionsDetail: {
        addRemoveLinks: true,
        thumbnailMethod: "contain",
        acceptedFiles: "image/*",
        destroyDropzone: true,
        url: () => {
          return process.env.VUE_APP_API_URL + "upload-image";
        },
      },
      options: {
        images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
        images_file_types: "jpg,svg,webp,gif,png",
        images_upload_handler: async (blobInfo, success, failure) => {
          let formData = new FormData();
          let _file = new File([blobInfo.blob()], blobInfo.filename(), {
            lastModified: new Date().getTime(),
            type: blobInfo.blob().type,
          });
          formData.append(
            "lending_settings_id",
            this.$store.state.landing.settings_id
          );
          formData.append("image", _file);
          formData.append("file", _file);
          const { data } = await this.$axios.post(
            `${process.env.VUE_APP_API_URL}upload-image`,
            formData
          );
          success(data);
        },
        plugins:
          "print preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount  imagetools textpattern noneditable help formatpainter permanentpen pageembed charmap quickbars emoticons advtable export",

        mobile: {
          images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
          images_file_types: "jpg,svg,webp,gif,png",
          images_upload_handler: async (blobInfo, success, failure) => {
            let formData = new FormData();
            let _file = new File([blobInfo.blob()], blobInfo.filename(), {
              lastModified: new Date().getTime(),
              type: blobInfo.blob().type,
            });
            formData.append(
              "lending_settings_id",
              this.$store.state.landing.settings_id
            );
            formData.append("image", _file);
            formData.append("file", _file);
            const { data } = await this.$axios.post(
              `${process.env.VUE_APP_API_URL}upload-image`,
              formData
            );
            success(data);
          },
          plugins:
            "print preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount textpattern noneditable help formatpainter pageembed charmap quickbars emoticons advtable",
        },
        menu: {
          tc: {
            title: "Comments",
            items: "addcomment showcomments deleteallconversations",
          },
        },
        menubar: "file edit view insert format tools table tc help",
        toolbar:
          "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | numlist bullist checklist | forecolor backcolor casechange   | charmap emoticons | insertfile image | custom_button",
        autosave_ask_before_unload: true,min_height:200,
        autosave_interval: "30s",
        autosave_prefix: "{path}{query}-{id}-",
        autosave_restore_when_empty: false,
        autosave_retention: "2m",
        image_advtab: true,
        link_list: [
          { title: "My page 1", value: "https://www.tiny.cloud" },
          { title: "My page 2", value: "http://www.moxiecode.com" },
        ],
        image_list: [
          { title: "My page 1", value: "https://www.tiny.cloud" },
          { title: "My page 2", value: "http://www.moxiecode.com" },
        ],
        image_class_list: [
          { title: "None", value: "" },
          { title: "Some class", value: "class-name" },
        ],
        importcss_append: true,
        templates: [
          {
            title: "New Table",
            description: "creates a new table",
            content:
              '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td class="d-block d-sm-table-cell"> </td><td class="d-block d-sm-table-cell"> </td></tr></table></div>',
          },
          {
            title: "Starting my story",
            description: "A cure for writers block",
            content: "Once upon a time...",
          },
          {
            title: "New list with dates",
            description: "New List with dates",
            content:
              '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
          },
        ],
        template_cdate_format: "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
        template_mdate_format: "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
        height: 200,
        image_caption: true,
        quickbars_selection_toolbar:
          "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
        noneditable_noneditable_class: "mceNonEditable",
        toolbar_mode: "sliding",
        content_style: ".mymention{ color: gray; }",
        contextmenu: "link image imagetools table configurepermanentpen",
        skin: "oxide",

        setup: function (editor) {
          editor.ui.registry.addButton("custom_button", {
            text: "Add Button",
            onAction: function () {
              // Open a Dialog
              editor.windowManager.open({
                title: "Add custom button",
                body: {
                  type: "panel",
                  items: [
                    {
                      type: "input",
                      name: "button_label",
                      label: "Button label",
                      flex: true,
                    },
                    {
                      type: "input",
                      name: "button_href",
                      label: "Button href",
                      flex: true,
                    },
                    {
                      type: "selectbox",
                      name: "button_target",
                      label: "Target",
                      items: [
                        { text: "None", value: "" },
                        { text: "New window", value: "_blank" },
                        { text: "Self", value: "_self" },
                        { text: "Parent", value: "_parent" },
                      ],
                      flex: true,
                    },
                    {
                      type: "selectbox",
                      name: "button_rel",
                      label: "Rel",
                      items: [
                        { text: "No value", value: "" },
                        { text: "Alternate", value: "alternate" },
                        { text: "Help", value: "help" },
                        { text: "Manifest", value: "manifest" },
                        { text: "No follow", value: "nofollow" },
                        { text: "No opener", value: "noopener" },
                        { text: "No referrer", value: "noreferrer" },
                        { text: "Opener", value: "opener" },
                      ],
                      flex: true,
                    },
                    {
                      type: "selectbox",
                      name: "button_style",
                      label: "Style",
                      items: [
                        { text: "Success", value: "success" },
                        { text: "Info", value: "info" },
                        { text: "Warning", value: "warning" },
                        { text: "Error", value: "error" },
                      ],
                      flex: true,
                    },
                  ],
                },
                onSubmit: function (api) {
                  var html =
                    '<a href="' +
                    api.getData().button_href +
                    '" class="v-btn v-btn--outlined theme--light v-size--small ' +
                    api.getData().button_style +
                    '--text" rel="' +
                    api.getData().button_rel +
                    '" target="' +
                    api.getData().button_target +
                    '">' +
                    api.getData().button_label +
                    "</a>";

                  // insert markup
                  editor.insertContent(html);

                  // close the dialog
                  api.close();
                },
                buttons: [
                  {
                    text: "Close",
                    type: "cancel",
                    onclick: "close",
                  },
                  {
                    text: "Insert",
                    type: "submit",
                    primary: true,
                    enabled: false,
                  },
                ],
              });
            },
          });
        },
        content_css: ["/custom_tinymce.css"],
      },
      objectItem: null,
      addEventDialog: false,
      addEventCategoryDialog:false,
      editEventCategoryDialog:false,
      editEventDialog: false,
      prevGroup:null,
      editEventCategoryModel:{
        show_on_main_page:0,
        text:{
          en:"default"
        },
        hotel_id:null
      },
      addEventCategoryModel:{
        text:{
          en:"default"
        },
        hotel_id:null
      },
      eventCategoryDefaultModel:{
        text:{
          en:"default"
        },
        hotel_id:null
      },
      eventDefault: {
        show_on_main_page:0,
        hotel_id: null,
        event_category_id:null,
        name: {
          en: "default name",
        },
        description: {
          en: "default description",
        },
        full_description: {
          en: "default full description",
        },
        show_full: false,
        show_event: true,
        edit: false,
        dialog: false,
        date: [],
        time: "",
        times:[],
        video_link: {
          en: "",
        },
        main_image: "",
        images: [],
      },
      addEventDefault: {
        show_on_main_page:0,
        event_category_id:null,
        hotel_id: null,
        name: {
          en: "default name",
        },
        description: {
          en: "default description",
        },
        full_description: {
          en: "default full description",
        },
        show_full: false,
        show_event: true,
        edit: false,
        dialog: false,
        date: [],
        time: "",
        times:[],
        video_link: {
          en: "",
        },
        main_image: "",
        images: [],
      },
      editEventDefault: {
        hotel_id: null,
        name: {
          en: "default name",
        },
        description: {
          en: "default description",
        },
        full_description: {
          en: "default full description",
        },
        video_link: {
          en: "",
        },
        event_category_id:null,
        show_full: false,
        show_event: true,
        edit: false,
        dialog: false,
        date: "",
        time: "",
        times:[],
        main_image: "",
        images: [],
      },
      selected_lang: 0,
      addEventDateDialog: false,
      addEventTimeDialog: false,
      editEventDateDialog: false,
      editEventTimeDialog: false,
      date_end: null,
      date_start: null,
      headers: [
        { text: "Image", value: "main_image", align: "center", sortable: true },
        { text: "Date/Time", value: "date", align: "center", sortable: true },
        { text: "Name", value: "name", align: "center", sortable: false },
        { text: "Active", align: "center" },
        { text: "Show on main page", align: "center" },
        { text: "Options", align: "center", sortable: false },
      ],
      headersCategories:[
        { text: "ID", value: "id", align: "center", sortable: true },
        { text: "Name", align: "center"},
        { text: "Events count", align: "center"},
        {text:"Options",align: "center"}
      ],
      minHour: 0,
      maxHour: 23,
      hour: now.getHours(),
      minMinute: 0,
      maxMinute: 59,
      minute: now.getMinutes(),
    };
  },
  beforeRouteEnter(to, from, next) {

      if(!store.state.objects.list.length){

          store.dispatch('objects/getList')
              .then((data) => {
                  next();
              }).catch(() => {
              next();
          })
      }else{
          next();
      }
  },
  watch: {
    editEventDialog: function (val) {
      if (val) {
        if (this.$refs.editEventMainDropzone) {
          if (this.editEventDefault.main_image) {
            let mockFile = {
              accepted: true,
              kind: "image",
            };
            this.$refs.editEventMainDropzone.manuallyAddFile(
              mockFile,
              this.editEventDefault.main_image
            );
            this.$refs.editEventMainDropzone.dropzone.emit(
              "thumbnail",
              mockFile,
              this.editEventDefault.main_image
            );
            this.$refs.editEventMainDropzone.dropzone.emit(
              "complete",
              mockFile
            );
          }
        }
        if (this.$refs.editEventDetailDropzone) {
          if (this.editEventDefault.images.length) {
            for (let i = 0; i < this.editEventDefault.images.length; i++) {
              let mockFile = {
                accepted: true,
                kind: "image",
                xhr: {
                  response: this.editEventDefault.images[i],
                },
              };
              this.$refs.editEventDetailDropzone.manuallyAddFile(
                mockFile,
                this.editEventDefault.images[i]
              );
              this.$refs.editEventDetailDropzone.dropzone.emit(
                "thumbnail",
                mockFile,
                this.editEventDefault.images[i]
              );
              this.$refs.editEventDetailDropzone.dropzone.emit(
                "complete",
                mockFile
              );
            }
          }
        }
      } else {
        this.$refs.editEventMainDropzone.removeAllFiles(true);
        this.$refs.editEventDetailDropzone.removeAllFiles(true);
      }
    },
    hour: function(hour) {
      this.$nextTick(() => {
        if (hour < this.minHour) this.hour = this.minHour;
        if (hour > this.maxHour) this.hour = this.maxHour;
      });
    },
    minute: function(minute) {
      this.$nextTick(() => {
        if (minute < this.minMinute) this.minute = this.minMinute;
        if (minute > this.maxMinute) this.minute = this.maxMinute;
      });
    },
  },
  methods: {
    ...mapActions('events',['getEventCategories','addEventCategory','deleteEventCategory','editEventCategory']),
    ...mapMutations('events',['setEventCategory']),
    forceTwoDigits(num) {
      return (num < 10 ? '0' : '') + num;
    },
    onAddHourClicked() {
      this.hour < this.maxHour ? this.hour += 1 : this.hour = this.minHour;
    },
    onSubtractHourClicked() {
      this.hour > this.minHour ? this.hour -= 1 : this.hour = this.maxHour;
    },
    onAddMinuteClicked() {
      this.minute < this.maxMinute ? this.minute += 1 : this.minute = this.minMinute;
    },
    onSubtractMinuteClicked() {
      this.minute > this.minMinute ? this.minute -= 1 : this.minute = this.maxMinute;
    },
    changeAddModelMainPage(obj){
      if(this.addModelMainPage){
        obj.show_on_main_page = 1;
      }else{
        obj.show_on_main_page = 0;
      }
    },
    changeLang(selected_lang){
      this.$store.state.landing.current_lang = selected_lang;
    },
    backToCategoryList(){
      this.page = 'categories';
      this.setEventCategory(null);
    },
    openEventList(category){
      this.setEventCategory(category);
      this.page = 'events';
    },
    openEditEventCategory(category){
      this.editEventCategoryModel = JSON.parse(JSON.stringify(category));
      this.editEventCategoryDialog = true;
    },
    async editEventCategoryConfirm(){
      await this.editEventCategory(this.editEventCategoryModel);
      this.successMessage = 'Your event category edited successfully!';
      this.editEventCategoryDialog = false;
      this.$refs.PopupSuccess.open();
    },
    openDeleteEventCategory(id){
      this.deleteEventCategoryId = id;
      this.$refs.PopupDeleteCategory.open();
    },
    async deleteEventCategoryConfirm(){
      await this.deleteEventCategory(this.deleteEventCategoryId);
      this.successMessage = 'Your event category deleted successfully!';
      this.$refs.PopupSuccess.open();
    },
    async saveNewEventCategory(){
      this.addEventCategoryModel.hotel_id = this.objectItem.id;
      await this.addEventCategory(this.addEventCategoryModel);
      this.successMessage = 'You event category created successfully!';
      this.addEventCategoryDialog = false;
      this.$refs.PopupSuccess.open();
    },
    openAddEventCategoryDialog(){
      this.addEventCategoryModel = JSON.parse(JSON.stringify(this.eventCategoryDefaultModel));
      this.addEventCategoryDialog = true;
    },
    addTime(){
      const time = `${this.hourModel}:${this.minuteModel}`;
      this.addEventDefault.times.push(time);
      this.addEventDateDialog =  false;
      this.addEventDefault.time = this.$moment().format("HH:mm");
    },
    async activeAllConfirm() {
      for (let i = 0; i < this.selectedRows.length; i++) {
        const candidate = this.filteredEvents.find(
          (x) => x.id === this.selectedRows[i]
        );
        if(candidate){
          candidate.show_event = 1;
        }
        await store.dispatch("events/editEvent", candidate);
      }
      this.selectedRows = [];
      this.successMessage = `Events visible on!`;
      this.$refs.PopupSuccess.open();
    },
    async disableAllConfirm() {
      for (let i = 0; i < this.selectedRows.length; i++) {
        const candidate = this.filteredEvents.find(
          (x) => x.id === this.selectedRows[i]
        );
        if(candidate){
          candidate.show_event = 0;
        }
        await store.dispatch("events/editEvent", candidate);
      }
      this.selectedRows = [];
      this.successMessage = `Events visible off!`;
      this.$refs.PopupSuccess.open();
    },
    activeAllShow() {
      this.$refs.PopupConfirmActiveAll.open();
    },
    disableAllShow() {
      this.$refs.PopupConfirmDisableAll.open();
    },
    deleteAllSelectedShow() {
      this.$refs.PopupDeleteAll.open();
    },
    async deleteAllSelected() {
      for (let i = 0; i < this.selectedRows.length; i++) {
        await store.dispatch("events/deleteEvent", this.selectedRows[i]);
      }
      this.selectedRows = [];
      this.successMessage = "Events deleted successful!";
      this.$refs.PopupSuccess.open();
    },
    rowClicked(row) {
      this.toggleSelection(row.id);
    },
    rowClickedCategory(row){
      console.log(row);
    },
    toggleSelection(keyID) {
      if (this.selectedRows.includes(keyID)) {
        this.selectedRows = this.selectedRows.filter(
          (selectedKeyID) => selectedKeyID !== keyID
        );
      } else {
        this.selectedRows.push(keyID);
      }
    },
    changeDates() {
      this.menu_dates = false;
      if (this.dates[0] && this.dates[1]) {
        if (this.dates[0] > this.dates[1]) {
          let start = this.dates[0];
          let end = this.dates[1];
          this.dates[0] = end;
          this.dates[1] = start;
        }
      }
      this.dateRangeText();
    },
    dateRangeText() {
      let date_start = null;
      let date_end = null;
      if (this.dates[0] && this.dates[1]) {
        date_start = this.$moment(this.dates[0]).format("DD.MM.YYYY");
        date_end = this.$moment(this.dates[1]).format("DD.MM.YYYY");
      } else if (this.dates[0]) {
        date_start = this.$moment(this.dates[0]).format("DD.MM.YYYY");
      } else if (this.dates[1]) {
        date_end = this.$moment(this.dates[1]).format("DD.MM.YYYY");
      } else {
        this.date_model = "";
      }

      this.date_model = [date_start, date_end].join(" - ");
    },
    changeDatesArrFormat(dates,times) {
      return dates.reduce((acc, item,index) => {
        if (item) {
          if(times[index]){
            acc.push(this.formatDate(item) + " " +times[index]);
          }else{
            acc.push(this.formatDate(item));
          }
        }
        return acc;
      }, []);
    },
    mountEditMainDropzone() {
      if (this.editEventDefault.main_image) {
        let mockFile = {
          accepted: true,
          kind: "image",
        };
        this.$refs.editEventMainDropzone.manuallyAddFile(
          mockFile,
          this.editEventDefault.main_image
        );
        this.$refs.editEventMainDropzone.dropzone.emit(
          "thumbnail",
          mockFile,
          this.editEventDefault.main_image
        );
        this.$refs.editEventMainDropzone.dropzone.emit("complete", mockFile);
      }
    },
    mountEditDetailDropzone() {
      if (this.editEventDefault.images.length) {
        for (let i = 0; i < this.editEventDefault.images.length; i++) {
          let mockFile = {
            accepted: true,
            kind: "image",
            xhr: {
              response: this.editEventDefault.images[i],
            },
          };
          this.$refs.editEventDetailDropzone.manuallyAddFile(
            mockFile,
            this.editEventDefault.images[i]
          );
          this.$refs.editEventDetailDropzone.dropzone.emit(
            "thumbnail",
            mockFile,
            this.editEventDefault.images[i]
          );
          this.$refs.editEventDetailDropzone.dropzone.emit(
            "complete",
            mockFile
          );
        }
      }
    },
    openEditEventDialog(event) {
      this.addModelMainPage = !!event.show_on_main_page;
      this.prevGroup = event.event_category_id;
      this.editEventDefault = JSON.parse(JSON.stringify(event));
      this.editEventDialog = true;
    },
    closeEditEventDialog() {
      this.editEventDialog = false;
      this.$refs.editEventMainDropzone.removeAllFiles();
      this.editEventDefault = JSON.parse(JSON.stringify(this.eventDefault));
      this.editEventDefault.date = "";
    },
    async changeEventVisibilityOnMain(event){
      if (event.show_on_main_page) {
        event.show_on_main_page = 0;
      } else {
        event.show_on_main_page = 1;
      }

      await store.dispatch("events/editEvent", event);
      this.successMessage = `Event visible on main page ${event.show_on_main_page ? "show" : "hide"}!`;
      this.$refs.PopupSuccess.open();
    },
    async changeEventVisibility(event) {
      if (event.show_event) {
        event.show_event = 0;
      } else {
        event.show_event = 1;
      }

      await store.dispatch("events/editEvent", event);
      this.successMessage = `Event visible ${event.show_event ? "on" : "off"}!`;
      this.$refs.PopupSuccess.open();
    },
    async deleteEventConfirm() {
      await store.dispatch("events/deleteEvent", this.deleteEventId.id);
      this.deleteEventId = null;
      this.successMessage = "Event deleted successful!";
      this.$refs.PopupSuccess.open();
    },
    openDeleteDialog(item) {
      this.deleteEventId = item;
      this.$refs.PopupDelete.open();
    },
    async customEvent(_file, xhr, formData) {
      xhr.setRequestHeader(
        "Authorization",
        `Bearer ${this.$store.state.token}`
      );
      formData.append(
        "lending_settings_id",
        this.$store.state.landing.settings_id
      );
      formData.append("image", _file);
    },
    logoMaxFileSexceededAddEventMain(file) {
      this.$refs.addEventMainDropzone.removeAllFiles();
      this.$refs.addEventMainDropzone.addFile(file);
    },
    handleSuccessAddEventMain(file) {
      this.addEventDefault.main_image = file.xhr.response;
    },
    handleRemoveAddEventMain() {
      this.addEventDefault.main_image = "";
    },
    logoMaxFileSexceededEditEventMain(file) {
      this.$refs.editEventMainDropzone.removeAllFiles();
      this.$refs.editEventMainDropzone.addFile(file);
    },
    handleSuccessEditEventMain(file) {
      this.editEventDefault.main_image = file.xhr.response;
    },
    handleRemoveEditEventMain() {
      this.editEventDefault.main_image = "";
    },
    handleSuccessAddEventDetail(file) {
      this.addEventDefault.images.push(file.xhr.response);
    },
    handleRemoveAddEventDetail(file) {
      this.addEventDefault.images.splice(
        this.addEventDefault.images.indexOf(file.xhr.response),
        1
      );
    },
    handleSuccessEditEventDetail(file) {
      this.editEventDefault.images.push(file.xhr.response);
    },
    handleRemoveEditEventDetail(file) {
      this.editEventDefault.images.splice(
        this.editEventDefault.images.indexOf(file.xhr.response),
        1
      );
    },
    async saveEditEvent() {
      this.editEventDefault.prevGroup = this.prevGroup;
      await store.dispatch("events/editEvent", this.editEventDefault);
      this.successMessage = "Event edited succesfully";
      this.editEventDialog = false;
      this.$refs.PopupSuccess.open();
    },
    openAddEventDialog(category) {
        if(category){
          this.setEventCategory(category);
        }
        if(this.$refs.addEventMainDropzone)
        {
            this.$refs.addEventMainDropzone.removeAllFiles();
        }
        if(this.$refs.addEventDetailDropzone)
        {
            this.$refs.addEventDetailDropzone.removeAllFiles();
        }

      this.addEventDefault = JSON.parse(JSON.stringify(this.eventDefault));
      this.addEventDefault.time = this.$moment().format("HH:mm");
      this.addEventDialog = true;
    },
    closeAddEventDate() {
      this.addEventDateDialog = false;
    },
    closeEditEventDate() {
      this.editEventDateDialog = false;
    },
    async changeHotel() {
      this.eventDefault.hotel_id = this.objectItem.id;
      await this.getEventCategories(this.objectItem.id);
      await store.dispatch("landing/getHotelLangs", this.objectItem.id);
      await store.dispatch("landing/getSettingsId", this.objectItem.id);
      for (let i = 0; i < this.selected_langs.length; i++) {
        if(!this.eventCategoryDefaultModel.text[this.selected_langs[i]]){
          this.eventCategoryDefaultModel.text[this.selected_langs[i]] = this.eventCategoryDefaultModel.text['en'].split("").join("");
        }
        if (!this.eventDefault.name[this.selected_langs[i]]) {
          this.eventDefault.name[this.selected_langs[i]] =
            this.eventDefault.name.en.split("").join("");
        }
        if (!this.eventDefault.description[this.selected_langs[i]]) {
          this.eventDefault.description[this.selected_langs[i]] =
            this.eventDefault.name.en.split("").join("");
        }
        if (!this.eventDefault.full_description[this.selected_langs[i]]) {
          this.eventDefault.full_description[this.selected_langs[i]] =
            this.eventDefault.name.en.split("").join("");
        }
        if (!this.eventDefault.video_link[this.selected_langs[i]]) {
          this.eventDefault.video_link[this.selected_langs[i]] =
            this.eventDefault.video_link.en.split("").join("");
        }
      }
    },
    closeEventDialog() {
      this.addEventDialog = false;
      this.addEventDefault = JSON.parse(JSON.stringify(this.eventDefault));
    },
    closeEventCategoryDialog(){
      this.addEventCategoryDialog = false;
      this.addEventCategoryModel = JSON.parse(JSON.stringify(this.eventCategoryDefaultModel));
    },
    async saveNewEvent() {
      this.addEventDefault.event_category_id = this.eventCategory.id;
      for (let i = 0; i < this.addEventDefault.date.length; i++) {
        const event = JSON.parse(JSON.stringify(this.addEventDefault));
        event.date = this.addEventDefault.date[i];
        event.time = this.addEventDefault.times[i];
        await this.$store.dispatch("events/createNewEvent", event);
      }

      this.successMessage = "New event added successful";
      this.addEventDialog = false;
      this.addEventDefault = JSON.parse(JSON.stringify(this.eventDefault));
      this.$refs.PopupSuccess.open();
    },
    formatDate(date) {
      return date ? this.$moment(date).format("DD.MM.YYYY") : "";
    },
  },
  computed: {
    ...mapState(["objects"]),
    ...mapState("events", ["eventList","eventCategories",'eventCategory']),
    ...mapState("landing", ["selected_langs"]),
    ...mapState(["landing"]),
    hourModel: {
      get() {
        return this.forceTwoDigits(this.hour);
      },
      set(v) {
        this.hour = Number(v);
      },
    },
    minuteModel: {
      get() {
        return this.forceTwoDigits(this.minute);
      },
      set(v) {
        this.minute = Number(v);
      },
    },
    filteredEvents() {
      if(this.page === 'events'){
        return this.eventCategory.events.filter(
            (event) =>
                +this.$moment(this.dates[0]).format("x") <=
                +this.$moment(event.date).format("x") &&
                +this.$moment(event.date).format("x") <=
                +this.$moment(this.dates[1]).format("x")
        );
      }else{
        return [];
      }
    },
  },
  async mounted() {
    this.dates[0] = this.$moment().format("YYYY-MM-DD");
    this.dates[1] = this.$moment().add(1, "months").format("YYYY-MM-DD");
    this.dateRangeText();
    if (this.objects.list.length) {
      if (this.$route.query.id) {
        const candidate = this.objects.list.find(
          (x) => x.id === +this.$route.query.id
        );
        if (candidate) {
          this.objectItem = candidate;
        } else {
          this.objectItem = this.objects.list[0];
        }
      } else {
        this.objectItem = this.objects.list[0];
      }
      this.eventDefault.hotel_id = this.objectItem.id;
      await this.getEventCategories(this.objectItem.id);
      await store.dispatch("landing/getHotelLangs", this.objectItem.id);
      await store.dispatch("landing/getSettingsId", this.objectItem.id);
      for (let i = 0; i < this.selected_langs.length; i++) {
        if(!this.eventCategoryDefaultModel.text[this.selected_langs[i]]){
          this.eventCategoryDefaultModel.text[this.selected_langs[i]] = this.eventCategoryDefaultModel.text['en'].split("").join("");
        }
        if (!this.eventDefault.name[this.selected_langs[i]]) {
          this.eventDefault.name[this.selected_langs[i]] =
              this.eventDefault.name.en.split("").join("");
        }
        if (!this.eventDefault.description[this.selected_langs[i]]) {
          this.eventDefault.description[this.selected_langs[i]] =
              this.eventDefault.name.en.split("").join("");
        }
        if (!this.eventDefault.full_description[this.selected_langs[i]]) {
          this.eventDefault.full_description[this.selected_langs[i]] =
              this.eventDefault.name.en.split("").join("");
        }
        if (!this.eventDefault.video_link[this.selected_langs[i]]) {
          this.eventDefault.video_link[this.selected_langs[i]] =
              this.eventDefault.video_link.en.split("").join("");
        }
      }
    }
  },
};
</script>

<style lang="scss">
.event_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.event__textfield {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 15px 0;
}

.label {
  margin-bottom: 15px;
}
.date_time {
  display: flex;
  width: 100%;
  .date {
    width: 100%;
    margin-bottom: 15px;
    .label {
      display: block;
      margin-bottom: 15px;
    }
  }
  .time {
    width: 200px;
    margin-bottom: 15px;
    .label {
      display: block;
      margin-bottom: 15px;
    }
  }
}
.event__img {
  padding: 15px 0;
  .label {
    display: block;
    margin-bottom: 15px;
  }
}
.vue-dropzone > .dz-preview .dz-details {
  display: none;
}
.vue-dropzone > .dz-preview .dz-image {
  width: 120px;
  height: 120px;
}
.v-card > *:first-child:not(.v-btn):not(.v-chip):not(.v-avatar), .v-card > .v-card__progress + *:not(.v-btn):not(.v-chip):not(.v-avatar){
  border-radius: 0;
}
.theme--light.v-picker__body{
  height:310px;
}
.v-picker--time .v-picker__title{
  height: 88px;
}
.tox.tox-tinymce{
  width:100%;
}
.tariff_obj {
  margin-left:5px;
  display: inline-block;
  padding: 4px 6px;
  text-transform: uppercase;
  border-radius: 0 5px 0 5px;
  background: #007db5;
  font-weight: 600;
  font-size: 12px;
  line-height: 1;
  color: #fff;
}
</style>
